// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/checkbox.ts

import { ComponentStyleConfig } from "@chakra-ui/react";

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      // 1px is too thin.
      // border: "1px solid",
    },
  },
};
