const gray = {
  50: "#FAFAFA",
  100: "#F2F2F2",
  200: "#D9D9D9",
  300: "#D5D5D5",
  400: "#AEAEAE",
  500: "#808080",
  600: "#666666",
  700: "#373737",
  800: "#202020",
  900: "#191919",
};

// const gray = {
//   50: "#F7FAFC",
//   100: "#EDF2F7",
//   200: "#D9DEE4",
//   300: "#CBD5E0",
//   400: "#A0AEC0",
//   500: "#718096",
//   600: "#4A5568",
//   700: "#2D3748",
//   800: "#1A202C",
//   900: "#171923",
// };

const brand = {
  50: "#ECF9FD",
  100: "#C8EEF9",
  200: "#91DEF3",
  300: "#47C7EB",
  400: "#16A3CA",
  500: "#0E7EAA",
  600: "#126991",
  700: "#15557A",
  800: "#164564",
  900: "#17374F",
};

const orange = {
  50: "#FCECE7",
  100: "#F7C6B6",
  200: "#F2A68C",
  300: "#EF8E6D",
  400: "#E9683D",
  500: "#E4420C",
  600: "#B6350A",
  700: "#892807",
  800: "#5B1A05",
  900: "#441404",
};

export const colors = {
  black: gray["900"],
  // gray,
  brand,
  orange,
  defaultBackground: "#F5F8FA",
};
