import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import Router from "next/router";
import "focus-visible/dist/focus-visible";
import { setUser } from "@sentry/nextjs";

import { theme } from "theme";
import { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { GlobalServerValuesProvider } from "@/common/components/GlobalServerValuesProvider";
import { QueryParamProvider } from "@/common/components/QueryParamsProvider";
import { Loading, loading } from "@/common/components/Loading";
import { useNavbarNextjs } from "@/common/hooks/useNavbar";

const useNextjsLoading = () => {
  // Source: https://stackoverflow.com/a/60756105/1744633
  // This useState and PageLoadingContext was causing a serious performance issue
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const start = (path, options) => {
      if (options.shallow) return;
      loading.start();

      // we dispatch this event so we can know about path changes in the navbar
      const changePathEvent = new CustomEvent("nextjsRouteChangeStart", { detail: { url: path } });
      document.dispatchEvent(changePathEvent);
      // setLoading(true);
    };
    const end = (path, options) => {
      if (options.shallow) return;
      loading.finish();
      // we dispatch this event so we can know about path changes in the navbar
      const changePathEvent = new CustomEvent("nextjsRouteChangeEnd", { detail: { url: path } });
      document.dispatchEvent(changePathEvent);
      // setLoading(false);
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  // return loading;
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  /**
   * CAUTION
   *
   * DO NOT add any state to this component. Re-rendering this component is too expensive.
   */
  useNavbarNextjs();
  useNextjsLoading();

  if (pageProps?.globalServerValues?.currentUser) {
    setUser({ username: pageProps.globalServerValues.currentUser?.username });
  }

  return (
    <GlobalServerValuesProvider value={pageProps.globalServerValues}>
      <QueryParamProvider>
        <ChakraProvider theme={theme}>
          <Loading color="var(--chakra-colors-brand-500)" />
          <Component {...pageProps} />
        </ChakraProvider>
      </QueryParamProvider>
    </GlobalServerValuesProvider>
  );
};

export default appWithTranslation(MyApp);
