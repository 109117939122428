import { GetServerSidePropsContext } from "next";

const UAParser = typeof window === "undefined" ? require("ua-parser-js") : null;

export const isMobileUserAgent = (ctx: GetServerSidePropsContext): boolean => {
  if (typeof window === "undefined" && UAParser) {
    const deviceType = UAParser(ctx.req.headers["user-agent"]).device.type;
    return deviceType === "mobile";
  }
  throw new Error("this function can only be called in server code");
};
