// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts

import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: "medium",
  },

  sizes: {
    lg: {
      fontSize: "md",
      px: 7,
    },
    md: {
      fontSize: "md",
      px: 6,
    },
    sm: {
      fontSize: "md",
      px: 4,
    },
    xs: {
      fontSize: "md",
      px: 3,
    },
  },
});
