import { keyframes } from "@emotion/react";

/**
 * Important note about CLS (Cumulative Layout Shift) from https://web.dev/cls/:
 *
 *   CSS `transform` property allows you to animate elements without triggering layout shifts:
 *   - Instead of changing the `height` and `width` properties, use `transform: scale()`.
 *   - To move elements around, avoid changing the `top`, `right`, `bottom`, or `left` properties
 *     and use `transform: translate()` instead.
 */

const LOADING_BODY_CLASSNAME = "_next__loading";

const indeterminateAnimation = keyframes`
0% {
  transform:  scaleX(0);
}
100% {
  transform:  scaleX(0.99);
}
`;

export const loading = {
  selector: `body.${LOADING_BODY_CLASSNAME} &`,
  start: () => {
    document.body.classList.add(LOADING_BODY_CLASSNAME);
  },
  finish: () => {
    document.body.classList.remove(LOADING_BODY_CLASSNAME);
  },
};

// Reference: https://codepen.io/tmac/pen/QgVRKb
export const Loading = ({ color }: { color: string }) => (
  <div
    css={{
      display: "none",
      [loading.selector]: { display: "block" },
      height: "3px",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 10000,
    }}
  >
    <div
      css={{
        width: "100%",
        height: "100%",
        backgroundColor: color,
        animation: `${indeterminateAnimation} 30s 1 cubic-bezier(0, 0.5, 0, 1)`,
        animationFillMode: "forwards",
        transformOrigin: "0% 50%",
      }}
    />
  </div>
);
