// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/heading.ts

import { ComponentStyleConfig } from "@chakra-ui/react";

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: "medium",
  },
  sizes: {
    md: {
      fontSize: ["md", "lg", "lg", "xl"],
    },
  },
};
