// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/form-label.ts

import { ComponentStyleConfig } from "@chakra-ui/react";

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "sm",
    marginBottom: 1,
  },
};
