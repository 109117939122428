// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts

import { ComponentStyleConfig } from "@chakra-ui/react";

export const Input: ComponentStyleConfig = {
  defaultProps: {
    focusBorderColor: "brand.500",
  },
  variants: {
    outline: () => ({
      field: {
        bg: "input.bg",
        _placeholder: {
          opacity: 1,
          color: "text.pale.extra",
        },
      },
    }),
  },
};
