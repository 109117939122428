import { useRouter } from "next/router";
import { useEffect } from "react";

// These slugs should match with the
// data-slug attributes in the qnav templates.
export enum NavbarPage {
  DeveloperCourse = "developer-course",
  DeveloperCollege = "developer-college",
  DeveloperBootCamp = "developer-bootcamp",
  DeveloperProblemSet = "developer-problemset",
  DeveloperContest = "developer-contest",
  DeveloperMagnet = "developer-magnet",
  DeveloperJobFair = "developer-job-fair",
  DeveloperCodeCup = "developer-codecup",
  DeveloperBlog = "developer-blog",
  TalentIntroContest = "talent-intro-contest",
  TalentIntroInterview = "talent-intro-interview",
  TalentPanelDashboard = "talent-panel-dashboard",
  TalentPanelInterview = "talent-panel-interview",
}

/**
 * Sets active page in qnav navbar.
 */
export const useNavbarActivePage = (activePage: NavbarPage): void => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelectorAll("#qnav a[data-slug]").forEach((navLinkDom) => {
        const navLinkDomSlug = navLinkDom.getAttribute("data-slug");

        // Set active page
        if (navLinkDomSlug === activePage) {
          navLinkDom.classList.add("active");
        } else {
          navLinkDom.classList.remove("active");
        }
      });
    }
    return () => {
      // Clean-up the changes on unmount. (remove all "active" classes)
      if (typeof window !== "undefined") {
        document.querySelectorAll("#qnav a[data-slug]").forEach((navLinkDom) => {
          navLinkDom.classList.remove("active");
        });
      }
    };
  }, [activePage]);
};

/**
 * Improves navigation between next.js pages through navbar.
 */
export const useNavbarNextjs = () => {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelectorAll("#qnav a[data-slug]").forEach((navLinkDom) => {
        const supportsNextjs = (navLinkDom.getAttribute("data-nextjs") || "").toLowerCase() === "true";

        // Navigate using Next.js router instead of a full page reload (when possible).
        if (supportsNextjs) {
          navLinkDom.addEventListener("click", (event) => {
            // @ts-ignore
            if (event.ctrlKey) return; // Do not modify the behavior of ctrl+click.
            event.preventDefault();
            router.push(navLinkDom.getAttribute("href"));
          });
        }
      });
    }
  }, [router]);
};
