// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/breadcrumb.ts

import { ComponentStyleConfig } from "@chakra-ui/react";

export const Breadcrumb: ComponentStyleConfig = {
  variants: {
    oneline: {
      container: {
        overflow: "hidden",
        ol: { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
        li: { display: "inline" },
      },
    },
  },
};
