// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/modal.ts
import { mode } from "@chakra-ui/theme-tools";
import { ComponentStyleConfig } from "@chakra-ui/react";

export const Modal: ComponentStyleConfig = {
  baseStyle: (props) => ({
    dialog: {
      bg: mode("white", "gray.800")(props),
      ".chakra-modal__footer": {
        borderTop: "1px solid",
        borderColor: "border.gray",
      },
    },
  }),
};
