import React, { ReactNode, useContext } from "react";
import { GetServerSidePropsContext } from "next";
import { gql } from "graphql-request";
import { isMobileUserAgent } from "@/common/utils/ua_device_detector";
import { GlobalServerCurrentUserFragment } from "@/gql-types";

/**
 * This file is imported in _app.tsx.
 * So be careful when importing modules in this file to keep the "_app" chunk as small as possible.
 */

export type ABVersion = "a" | "b" | "";

interface GlobalServerValues {
  currentUser?: GlobalServerCurrentUserFragment;
  isMobileUA: boolean;
  _publicCookies: Partial<{ [p: string]: string }>;
  serverTime: string;
  abTestVersion: ABVersion;
}

export interface GlobalServerValuesProp {
  globalServerValues: GlobalServerValues;
}

export const GlobalServerValuesProviderContext = React.createContext<GlobalServerValues>({
  currentUser: {
    __typename: "CurrentUserType",
    goftino_id: "",
    marketing_id: "",
    is_authenticated: false,
    full_name: "",
    username: "",
    first_name: "",
    last_name: "",
    feature_toggles: [],
    email: "",
    avatar: "",
    profile: {
      __typename: "_CurrentUserProfileType",
      employment_status: null,
      user_status: null,
      enable_public_profile: false,
      enable_public_profile_specials: false,
      progress_percent: 0,
      phone_number: null,
      verified_phone_number: false,
      school: null,
      national_id: "",
    },
  },
  isMobileUA: false,
  _publicCookies: {},
  serverTime: "",
  abTestVersion: "",
});

export const useGlobalServerValues = () => useContext(GlobalServerValuesProviderContext);

interface GlobalServerValuesProviderProps {
  value: GlobalServerValues;
  children: ReactNode;
}

export const GlobalServerValuesProvider = ({ value, children }: GlobalServerValuesProviderProps) => (
  <GlobalServerValuesProviderContext.Provider value={value}>{children}</GlobalServerValuesProviderContext.Provider>
);

interface globalServerOptionalValues {
  abTestVersion?: string;
}

export const getGlobalServerValues = (
  ctx: GetServerSidePropsContext,
  currentUser: GlobalServerCurrentUserFragment = null,
  optionalValues: globalServerOptionalValues = {},
): GlobalServerValues => {
  const publicCookies = {};
  Object.keys(ctx.req.cookies).forEach((ckey) => {
    if (ckey.startsWith("public_")) {
      publicCookies[ckey] = ctx.req.cookies[ckey];
    }
  });

  return {
    currentUser,
    isMobileUA: isMobileUserAgent(ctx),
    _publicCookies: publicCookies,
    serverTime: new Date().toISOString(), // We used Date here to avoid adding Temporal to "_app" chunk.
    abTestVersion: (optionalValues.abTestVersion || "").toLowerCase() as ABVersion,
  };
};

// IMPORTANT NOTE:
// You should add default value for everything that you add in
// below fragment to the context in createContext GlobalServerValues

getGlobalServerValues.fragments = {
  currentUser: gql`
    fragment GlobalServerCurrentUserFragment on CurrentUserType {
      is_authenticated
      username
      first_name
      last_name
      full_name
      feature_toggles
      email
      avatar
      profile {
        enable_public_profile
        enable_public_profile_specials
        employment_status
        progress_percent
        user_status
        phone_number
        verified_phone_number
        school {
          id
          name
        }
        national_id
      }
      goftino_id
      marketing_id
    }
  `,
};
