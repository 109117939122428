// Default: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/switch.ts

import { mode } from "@chakra-ui/theme-tools";
import { ComponentStyleConfig } from "@chakra-ui/react";

export const Switch: ComponentStyleConfig = {
  baseStyle: (props) => ({
    thumb: {
      bg: mode("white", "gray.700")(props),
    },
  }),
};
