import { mode } from "@chakra-ui/theme-tools";

export const styles = {
  global: (props) => ({
    body: {
      color: "text.normal",
      bg: mode("defaultBackground", "gray.900")(props),
      transitionProperty: "unset",
      transitionDuration: "unset",
      "--chakra-colors-chakra-placeholder-color": "text.pale",
    },
    html: {
      fontSize: 14,
      scrollBehavior: "smooth",
      colorScheme: mode("light", "dark")(props),
    },
    "p, ul, ol": {
      lineHeight: 2,
    },
    "*, *::before, &::after": {
      borderColor: "border.gray",
    },
  }),
};
